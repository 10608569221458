<template>
  <div class="vg_wrapper">
    <el-card>
      <div>
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getMconsNow()">
          <el-row>
            <el-col :md="6">
              <el-form-item label="合同编号：">
                <el-input size="small" v-model.trim="searchForm.mcon_no" clearable placeholder="请填写合同编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="6">
              <el-form-item label="单据状态：">
                <selectStatus ref="selectStatus" @selectStatusRow="getStatusVal"></selectStatus>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="交货时间：">
                <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue1"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="14">
              <el-form-item label="录入时间：">
                <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getMconsNow()" class="vg_ml_16">查询</el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <!-- <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button> -->
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table class="vg_pointer" ref="multiTable" :data="tableData" @selection-change="handleSelectionChange" @select="selectRows" @select-all="selectRows" @row-dblclick="dbClickJp" border v-loading="loadingFlag">
            <el-table-column type="selection" width="48"  align="center"/>
            <el-table-column label="合同编号" prop="mcon_no" />
            <el-table-column label="账套工厂名" prop="acct_fctr_name" />
            <el-table-column label="合作单位名" prop="tg_acct_fctr_name" />
            <el-table-column label="合同类型" prop="mcon_type" :formatter="formatType"/>
            <el-table-column label="交货时间" prop="mcon_date" :formatter="formatDate1" />
            <el-table-column label="录入时间" prop="create_time" :formatter="formatDate" />
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina"/>
            <el-table-column label="单据状态" align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">{{helper.getStatusName(scope.row.status).name}}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {mconAPI} from "@api/modules/mcon"
import selectStatus from "@/views/component/selectStatus";
import pubPagination from "@/components/common/pubPagination";
import {cpttAPI} from "@api/modules/comptitle";
import {acctAPI} from "@api/modules/acct";

export default {
  name: "MconList",
  components: {
    selectStatus,
    pubPagination,
  },
  data(){
    return{
      searchForm:{
        mcon_no:'',
        status:null,
        timeValue:{
          startTime:null,
          endTime:null
        },
        timeValue1:{
          startTime:null,
          endTime:null
        }
      },
      currentPage: 1,
      totalPage:0,
      tableData: [],
      multiSelection: [],
      btn:{},
      acctFctrGroupOpt:[],
      cpttGroupOpt:[],
      loadingFlag:true
    }
  },
  created() {
    this.initData()
  },
  watch:{
    $route(to,from){
      if(from.path === '/mcon_add' || from.path === '/mcon_edit'){
        this.initData()
      }
    },
  },
  methods: {
    initData(){
      this.loadingFlag = true
      this.getMconsList()
    },
    //获取物料采购列表
    getMconsList(){
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
      const timeNewVal1 = this.helper.getTime(this.searchForm.timeValue1)
      get(mconAPI.getMcons,
          {
            mcon_no: this.searchForm.mcon_no,
            status: this.searchForm.status,
            start_time: timeNewVal.startTime,
            end_time: timeNewVal.endTime,
            start_time2: timeNewVal1.startTime,
            end_time2: timeNewVal1.endTime,
            page_no: this.currentPage,
          })
          .then(res => {
            if(res.data.code === 0){
              this.tableData = res.data.data.list;
              this.totalPage = res.data.data.total
              this.btn = res.data.data.btn;
              setTimeout(() => {
                this.loadingFlag = false
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 获取账套工厂id
    getAcctFctrId(flag){
      if(flag === true && this.acctFctrGroupOpt.length === 0){
        get(acctAPI.getAcctFctrs)
        .then(res=>{
          if(res.data.code === 0){
            this.acctFctrGroupOpt = res.data.data.list
          }else{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          }
        })
        .catch((res)=>{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        })
      }
    },
    // 获取合作单位id
    getCpttId(flag){
      if(flag === true && this.cpttGroupOpt.length === 0){
        get(cpttAPI.getAllCpttsV1)
        .then(res=>{
          if(res.data.code === 0){
            this.cpttGroupOpt = res.data.data
          }else{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          }
        })
        .catch((res)=>{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        })
      }
    },
    // 刷新
    buttonRefresh(){
      this.$refs.pubPagination.currentPage = 1
      this.$refs.selectStatus.changeValue()
      this.searchForm = {
        mcon_no:'',
        status:null,
        timeValue:{
          startTime:null,
          endTime:null
        },
        timeValue1:{
          startTime:null,
          endTime:null
        }
      };
      this.currentPage = 1
      this.initData()
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time)
    },
    formatDate1(row) {
      return this.helper.toStringDate(row.mcon_date)
    },
    // 类型转换
    formatType(row){
      if(row.mcon_type == 0){
        return '采购合同'
      }else if(row.mcon_type == 1){
        return '销售合同'
      }
    },
    // 查询方法
    getMconsNow(){
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.initData()
    },
    // 状态查询
    getStatusVal(val){
      this.searchForm.status = val
    },
    // 分页查询
    changePageSearch(val){
      this.currentPage = val
      this.initData()
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection){
      this.btn.delete = this.helper.isDelete(selection)
    },
    //删除提示
    doDelete(){
      if(this.multiSelection.length === 0){
        this.$message({type:'warning',message:'请至少选择一条数据！'})
      } else {
        this.mBox()
      }
    },
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delRow()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
        this.$refs.multiTable.clearSelection();
      });
    },
    // 删除
    delRow(){
      const ids = []
      this.multiSelection.map((item)=>{
        ids.push(item.mcon_id)
      })
      post(mconAPI.deleteMconByIds,{'mcon_id_list':ids})
          .then(res=>{
            if(res.data.code === 0){
              let mg = res.data.msg;
              let tp = 'success';
              this.$message({message:mg,type:tp});
              this.initData()
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
              this.initData()
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
            this.initData()
          })
    },
    // 双击row跳转到详情编辑
    dbClickJp(row){
      const permId = this.$route.query.perm_id
      this.jump('/mcon_edit',{perm_id:permId,form_id:row.mcon_id})
    }
  }
}
</script>

<style scoped>

</style>